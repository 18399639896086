@import './variables.scss';

html
{
	box-sizing: border-box;
	color: $color-font-default;
	cursor: auto;
	font-family: $font-family-default;
	font-size: $font-size-default;
	line-height: 1.3rem;
	word-break: break-word;
}

*, *:before, *:after
{
	box-sizing: inherit;
}

body
{
	background: url('../../static/nervos-shapes-bg.jpg') no-repeat center center fixed; 
	background-color: $color-background-default;
	background-size: cover;
}

h1, h2, h3
{
	color: $color-nervos-green;
}

h1
{
	font-size: 24px;
	font-weight: 600;
	margin: 0 0 .4em 0;
}

h2
{
	font-size: 18px;
	font-weight: 600;
	margin: 0 0 .3em 0;
}

h3
{
	font-weight: 600;
}

p
{
	margin: 0 0 1em 0;
}

a
{
	color: $color-font-light;
	font-weight: bold;
	text-decoration: none;

	&:hover
	{
		text-decoration: underline;
	}
}

table
{
	border-collapse: collapse;
}

table, tr, td
{
	padding: 0;
	margin: 0;
}

table, tr, th
{
	padding: 0;
	margin: 0;
	text-align: left;
}
