@import '../../common/scss/variables.scss';
@import 'include-media/dist/_include-media.scss';

main.generator
{
	form
	{
		input[type="text"]
		{
			font-family: $font-family-monospace;
		}

		section
		{
			margin: 0 0 $margin-thick 0;
		}

		fieldset
		{
			margin: 0 0 $margin-thick 0;

			section
			{
				margin: 0;
			}
		}

		.segmented-control
		{
			margin: 0;
			transition-duration: 0.2s;

			label
			{
				color: $color-nervos-green;
			}
		}
	}

	.copy-container
	{
		position: relative;

		input[type="text"]
		{
			padding-right: 45px;

			&.private-key
			{
				padding-right: 65px;
			}
		}

		.copy-button,
		.generate-button,
		.qrcode-button
		{
			bottom: 5px;
			position: absolute;
			background-color: transparent;
			border: none;
			padding: 2px 4px 2px 4px;
			color: #000000;
			right: 5px;

			&:disabled
			{
				color: transparent;				
			}
		}

		.generate-button
		{
			&.valid
			{
				right: 45px;
			}
		}

		.qrcode-button
		{
			&.valid
			{
				right: 25px;
			}
		}
	}
}
