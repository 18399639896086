@import './variables.scss';

button
{
	background: $color-button-default;
	border: 2px solid transparent;
	border-radius: $border-radius-input;
	color: $color-font-light;
	cursor: pointer;
	padding: calc($padding-input/2) $padding-input calc($padding-input/2) $padding-input;

	&:active,
	&:hover
	{
		background: $color-button-hover;
	}

	&:active
	{
		border: 2px solid #ffffff;
	}

	&:disabled,
	&.loading
	{
		background: $color-button-disabled;
		color: $color-font-disabled;
	}
}

form
{
	display: block;
}

fieldset
{
	padding: $padding-thin $padding-default $padding-default $padding-default;
}

input
{
	border: none;
	display: block;
	padding: $padding-input;
	width: 100%;

	&[type="text"]
	{
		text-overflow: ellipsis;
	}

	&:read-only
	{
		background-color: $color-input-read-only;
	}

	&.invalid
	{
		background-color: $color-input-invalid;
	}
}

label
{
	display: block;
	font-size: 0.8rem;
	margin-top: 2px;
}
