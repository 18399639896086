@import '../../common/scss/variables.scss';
@import 'include-media/dist/_include-media.scss';

section.main
{
	position: relative;
}

main
{
	padding: $padding-thick*2 $padding-default $padding-thick*2 $padding-default;
	margin: 0 auto 0 auto;
	max-width: map-get($breakpoints, "desktop");
	min-width: 375px;
	width: 100%;
	z-index: $z-index-main;

	@include media("<=tablet")
	{
		padding: $padding-default;
	}
}

span.spacer
{
	display: inline-block;
	width: $margin-default;
}

.loading-spinner
{
	position: absolute;
	height: 50px;
	right: 0px;
	top: 0;
}

.reOverlay .reOverlay__modalWrapper .reOverlay__modalContainer
{
	background: black;
	border: 1px solid #cccccc;
	border-radius: $border-radius-default;
	padding: $padding-default;
	max-width: 500px;
	min-width: 375px;
	width: 100%;

	@include media("<=tablet")
	{
		border: none;
		border-radius: 0;
		height: 100%;
		max-width: none;
	}

	h1, h2, h3
	{
		margin-bottom: 1em;
	}

	.button-bar
	{
		margin-top: $margin-thick;
		text-align: center;
	}
}
