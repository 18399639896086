@import '../../common/scss/variables.scss';
@import 'include-media/dist/_include-media.scss';

main.home
{
	.logo
	{
		display: block;
		float: right;
		padding: 0 100px 0 50px;

		@include media("<=desktop")
		{
			margin: $margin-default;
			padding: 0;
		}

		@include media("<=tablet")
		{
			display: none;
		}
	}
}
