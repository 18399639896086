@import '../../common/scss/variables.scss';
@import 'include-media/dist/_include-media.scss';

main.sudt
{
	position: relative;

	table
	{
		&.loading
		{
			color: $color-font-disabled;
		}

		td
		{
			height: 23px;
			padding: $padding-cell $padding-default $padding-cell 0;

			&:first-child
			{
				font-weight: bold;
				white-space: nowrap;
			}

			&:last-child
			{
				font-family: $font-family-monospace;
				padding-right: 0;
			}
		}
	}

	.transactions
	{
		display: none;

		&.visible
		{
			display: block;
		}

		table
		{
			width: 100%;
			
			td, th
			{
				padding: 2px 10px 2px 0;

				&:nth-child(1)
				{
					.short
					{
						display: none;
					}

					@include media("<=tablet")
					{
						.long
						{
							display: none;
						}

						.short
						{
							display: inline;
						}
					}
				}

				&:nth-child(2)
				{
					min-width: 100px;
					width: 100px;
				}
			}

			td
			{
				font-family: $font-family-monospace;
			}
		}
	}

	.copy-button
	{
		background-color: transparent;
		border: none;
		margin-left: $margin-button;
		padding: 2px 4px 2px 4px;

		&:hover
		{
			color: $color-nervos-green;
		}

		&:active
		{
			border: none;
		}
	}

	section.chain-type
	{
		margin-bottom: $margin-default;

		.segmented-control
		{
			margin: 0;
			transition-duration: 0.2s;
	
			label
			{
				color: $color-nervos-green;
			}
		}
	}
}
